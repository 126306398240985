import { PrivatePage } from "pages/privatePage";
import { Container, SidebarContainer } from "./styles";
import SidebarComponent from "components/Sidebar";
import { Header } from "components/Header";
import SigninIllust from 'assets/signinIllust.svg'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Card from "components/Card";
import { useAuth } from "contexts/AuthContext";

export function Home() {
  const { username } = useAuth()
  let name = (username.split(".")[0] || "usuário")
  name = name.charAt(0).toUpperCase() + name.slice(1)
  const { userGroups } = useAuth();


  const boxControlAccess = userGroups.includes("BoxControl")
  const logistics03Access = userGroups.includes("Logística 03")
  const adminAccess = userGroups.includes("Admin")

  const home = <>
    <Container>
      <Header />
      <SidebarContainer>
        <SidebarComponent />
      </SidebarContainer>
      <div className="mainCard">
        <div className="textArea">
          <h1>Olá, {name}!</h1>
          <p>Bem-vindo ao Maracujá, aqui você encontra todos os dados de logística em um lugar só!</p>

          <div className="signupContainer">
            {adminAccess &&
            <a href='/signup'>
              <PersonAddIcon className="addIcon" />
              <span className="signupText">Cadastrar Usuário</span>
            </a>
            }
          </div>
        </div>
        <div className="imgArea">
          <img src={SigninIllust} alt="" />
        </div>
      </div>
      <div className="menuContainer">
        <div className="sectionTitle">
          <h2>O que você procura?</h2>
        </div>
        <div className="menuCards">
          {boxControlAccess && !logistics03Access && !adminAccess && (
            <>
              <Card title="Entrada de Caixas" goto="/box-count" description="Acompanhamento das entradas de caixas." />
              <Card title="Retorno de Caixas" goto="/box-return" description="Acompanhamento dos retornos de caixas." />
            </>
          )}

          {(logistics03Access || adminAccess) && (
            <>
              <Card title="Atribuição de rotas" goto="/routes" description="Dados das rotas" />
              <Card title="Motoristas" goto="/drivers" description="Dados dos motoristas" />
              <Card title="Status de Entregas" goto="/delivery-status" description="Estado das entregas" />
              <Card title="Score de Clientes" goto="/score-client" description="Pontuação dos Clientes" />
            </>
          )}
        </div>

      </div>
    </Container>
  </>

  return <>
    <PrivatePage>
      {home}
    </PrivatePage>
  </>
}
