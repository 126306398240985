import SearchIcon from 'assets/searchIcon.svg'
import { DriverSelectContainer, DriverTeamColorTag, SelectBack } from "./styles";
import { CSSProperties, useEffect, useState } from "react";
import { Input } from 'components/Input';
import { toast } from 'react-toastify';
import { LinearProgress } from '@mui/material';
import { routeService } from 'services/routeService';
import { IVehicleRotation } from 'interfaces/IWaybill';
import { dateToday } from 'utils/dateToday';

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  handleSelect: (x: any) => void,
  unassigned?: boolean,
  tailPosition: 'top' | 'left',
  style?: CSSProperties,
  driverId?: number,
  date?: string
}

export function DriverSelect({ open, setOpen, handleSelect, unassigned, tailPosition, style, driverId, date }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState<IVehicleRotation[]>([])
  const [loading, setLoading] = useState(false)
  const params = { pageSize: 300, page: 1, notPaginated: true, date: date ?? dateToday() }

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await routeService.getVehicleRotation(params)
      setEntries(response.results);
    } catch (error) {
      toast.error("Não foi possível buscar os motoristas no banco de dados");
    } finally {
      setLoading(false);
    }
  }

  const handlePick = (element: IVehicleRotation) => {
    handleSelect(element)
    setOpen(false)
  }

  const filteredNames = entries?.filter((element) => element?.driver?.name?.startsWith(searchTerm))

  useEffect(() => {
    fetchData()
  }, [open])

  if (!open) return null

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }} />
      <DriverSelectContainer style={style} tailPosition={tailPosition}>
        <div className="header">
          <div className="input">
            <img className="searchIcon" src={SearchIcon} alt="" />
            <Input
              type="text"
              className="inputBox"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Nome"
            />
          </div>
        </div>
        <div className="list">
          {loading && <LinearProgress variant="indeterminate" color='success' />}
          {entries && filteredNames
            .sort((a, b) => a.driver.name.localeCompare(b.driver.name))
            .filter((element) => element.driver?.id !== driverId && element.agileId !== null)
            .map((element, index) => (
              <div className="entry" key={index}>
                <DriverTeamColorTag barColor={element?.driver?.team?.colorTeam || "transparent"} />
                <button
                  type="button"
                  className="entryName"
                  onClick={() => handlePick(element)}
                >
                  {`${element.driver?.name} ${element?.driver?.surname || ""} - ${element.licensePlate || "Placa não informada"}`}
                </button>
              </div>
            ))
          }
        </div>
      </DriverSelectContainer >
    </>
  )
}
