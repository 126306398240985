import HomeIcon from '@mui/icons-material/Home';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import IconBoxReturn from '../../assets/IconBoxReturn.svg';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import IconPunctuality from '../../assets/iconPunctuality.svg';
import RouteIcon from '../../assets/routeIcon.svg';
import ScoreIcon from '../../assets/scoreIcon.svg';
import { PiExportFill } from "react-icons/pi";
import SupportIcon from '@mui/icons-material/Support';

export interface NavItem {
  title: string;
  path?: string | null;
  permissions?: string[];
  icon: any;
  children?: NavItem[];
}

const NavConfig: NavItem[] = [
  {
    title: "Home",
    permissions: [],
    path: "/home",
    icon: HomeIcon,
  },
  {
    title: "Entregas",
    permissions: ["Admin", "Logística 03"],
    path: "/deliveries",
    icon: LocalPostOfficeIcon,
  },
  {
    title: "Motoristas e Times",
    permissions: ["Admin", "Logística 03"],
    path: "/drivers",
    icon: LocalShippingIcon,
  },
  {
    title: "Status de Motoristas",
    permissions: ["Admin", "Logística 03"],
    path: "/support-drivers",
    icon: SupportIcon,
  },
  {
    title: "Status de Entregas",
    permissions: ["Admin", "Logística 03"],
    path: "/delivery-status",
    icon: StickyNote2Icon,
  },
  {
    title: "Entrada de Caixas",
    permissions: ["Admin", "Logística 03", "BoxControl"],
    path: "/box-count",
    icon: ArchiveRoundedIcon,
  },
  {
    title: "Devolução de Caixas",
    permissions: ["Admin", "Logística 03", "BoxControl"],
    path: "/box-return",
    icon: () => (<img src={IconBoxReturn} alt="Icon" />),
  },
  {
    title: "Clientes",
    permissions: ["Admin", "Logística 03"],
    path: "/clients",
    icon: () => (<img src={ScoreIcon} alt="Icon" />),
  },
  {
    title: "Pontualidade",
    permissions: ["Admin", "Logística 03"],
    path: "/driver-punctuality",
    icon: () => (<img src={IconPunctuality} alt="Icon" />),
  },
  {
    title: "Prioridades",
    permissions: ["Admin", "Logística 03"],
    path: "/priority",
    icon: MapIcon,
  },
  {
    title: "Atribuição de Rotas",
    permissions: ["Admin", "Logística 03"],
    path: "/routes",
    icon: () => (<img src={RouteIcon} alt="Icon" />),
  },
  {
    title: "Exportação de Rotas",
    permissions: ["Admin", "Logística 03"],
    path: "/export-routes",
    icon: () => <PiExportFill  style={{ color: '#A2A2A2', fontSize: '25.5px' }}/>,
  },
  {
    title: "Usuários",
    permissions: [],
    path: null,
    icon: PersonIcon,
  },
  {
    title: "Cadastrar Usuário",
    permissions: ["Admin"],
    path: "/signup",
    icon: PersonAddIcon,
  },
  {
    title: "Configurações",
    permissions: [],
    path: null,
    icon: SettingsIcon,
  },
];

export default NavConfig;
