import { IBoxes } from "interfaces/IBoxes"
import { FormContainer } from "./styles"
import { IVehicle } from "interfaces/IVehicle"
import { dateFormatWithHour } from "utils/dateFormat"

interface IEditReturnDateForm {
  data: IBoxes,
  setOpen: (x: boolean) => void
}

export interface IDriverChangeHistory {
  id: number
  route: string
  deliveryDate: string
  newVehicle: IVehicle
  oldVehicle: IVehicle
  created: string
}

export const ShowDriverChangeHistory = ({ data, setOpen }: IEditReturnDateForm) => {
  return (
    <div>
      <FormContainer>
        <div className='cardContainer'>
          <div className="dataCardContainer">
            {data?.driverChangeHistory?.map((element: IDriverChangeHistory) => {
              const oldDriverName = element?.oldVehicle?.driver?.name || "Sem Nome";
              const newDriverName = element?.newVehicle?.driver?.name || "Sem Nome";
              return (
                <div className="listData" key={element.id}>
                  <span>{dateFormatWithHour(element.created)}</span>
                  <span>{oldDriverName} &rarr; {newDriverName}</span>
                </div>
              )
            })}
          </div>
        </div>
      </FormContainer>
    </div>
  )
}
