import { EditButton } from "components/EditButton"
import { IBoxCountData, IBoxes, ISensitiveData } from "interfaces/IBoxes"
import { Line } from "../styles"
import { useState } from "react"
import { ObsBtnContainer, SensitiveBtnContainer } from "./styles";
import ObsIcon from 'assets/obsGray.svg'
import ObsNewIcon from 'assets/obsNotification.svg'
import EggIcon from 'assets/brokenEggIcon.svg'

interface IProps {
  element: IBoxes,
  toggle: boolean,
  onSubmit: (x: IBoxes) => void,
  handleEdit: (x: IBoxes) => void,
  handleEditCount: (data: IBoxCountData) => void,
  handleEditSensitive: (x: ISensitiveData) => void
}

export const FormTableLine = ({ element, toggle, onSubmit, handleEdit, handleEditCount, handleEditSensitive }: IProps) => {
  const [boxesConfirmed, setBoxesConfirmed] = useState(element.boxesConfirmed || element.boxesSent)
  const [data, setData] = useState<IBoxes>(element)

  const handleBoxAmount = (boxes: number) => {
    const finalValue = element.boxesSent - boxes
    if (finalValue === boxesConfirmed) return
    setBoxesConfirmed(finalValue)
    const newElement = { ...element, boxesConfirmed: finalValue }
    onSubmit(newElement)
  }

  return (
    <Line className="bodyLine">
      <td align="left" className='contentTable' style={{ paddingLeft: "2%" }}>
        <span>{toggle ? (data.route || "Sem Rota") : (data.vehicle?.driver?.name || "Sem Nome")}</span>
      </td>
      <td
        align="center"
        className='contentTable'
        onClick={(e: any) => e.target?.children[0]?.focus()}>
        <div className="boxAmount">
          {data.boxesSent ?
            <>
              <span className="boxesReturned">{boxesConfirmed}</span>
              <span className="boxesSent">de {data.boxesSent}</span>
            </> :
            <span>Sem Dados</span>
          }
        </div>
      </td>
      <td align="center" className="contentTable">
        <SensitiveBtnContainer onClick={() => handleEditSensitive({ route: element.route, driverName: element.vehicle?.name })}>
          <span className="sensitiveText">Conferir</span>
          <div className="imgContainer">
            <img src={EggIcon} />
          </div>
        </SensitiveBtnContainer>
      </td>
      <td align="center" className='contentTable'>
        <div className="iconBtns">
          <ObsBtnContainer onClick={() => handleEdit(element)}>
            <img src={data.countComment ? ObsNewIcon : ObsIcon} />
          </ObsBtnContainer>
          <EditButton onClick={() => handleEditCount(
            {
              route: data.route,
              driverName: data.vehicle?.driver?.name,
              handleBoxAmount: handleBoxAmount
            }
          )} />
        </div>
      </td>
    </Line>
  )
}
