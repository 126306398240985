import React, { useEffect, useRef } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { useProSidebar } from "react-pro-sidebar";
import { SidebarWrapper } from "./styles";
import { useLogout } from "hooks/useLogout";
import NavConfig, { NavItem } from "./navConfig";
import { useAuth } from "contexts/AuthContext";

function SidebarComponent() {
  const { collapseSidebar, collapsed } = useProSidebar();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const isTablet = useMediaQuery({ maxWidth: 820 });
  const isDesktop = useMediaQuery({ minWidth: 820 });
  const { userGroups } = useAuth();
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !collapsed && isTablet) {
        collapseSidebar(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef, collapsed, isTablet]);

  const handleClick = () => {
    toast.warning("Esta página está em desenvolvimento!");
  };

  const hasPermission = (groups: string[] | undefined): boolean => {
    if (groups?.length === 0) return true;
    return groups ? groups.some((permission) => userGroups.includes(permission)) : false;
  };

  const renderMenuItems = (items: NavItem[]) =>
    items.map((item, index) => {
      if (!hasPermission(item.permissions)) return null;

      if (item.children?.length) {
        return (
          <SubMenu key={index} label={item.title} icon={<item.icon className="menuIcon" style={{ color: "#A2A2A2" }} />}>
            {renderMenuItems(item.children)}
          </SubMenu>
        );
      }

      const isThirdLastItem = index === items.length - 3;

      return (
        <React.Fragment key={index}>
          {isThirdLastItem && <div className="line" />}
          <MenuItem
            component={<Link to={item.path || "#"} />}
            className="menuItem"
            icon={<item.icon className="menuIcon" style={{ color: "#A2A2A2", heigth: "10px" }} />}
            onClick={() => {
              if (!item.path) {
                handleClick();
              }
            }}
          >
            <b className="menuText">{item.title}</b>
          </MenuItem>
        </React.Fragment>
      );
    });


  return (
    <SidebarWrapper
      ref={sidebarRef}
      onMouseEnter={() => isDesktop && collapseSidebar(false)}
      onMouseLeave={() => isDesktop && collapseSidebar(true)}
    >
      <Sidebar className="sidebarContainer" defaultCollapsed collapsedWidth={isTablet ? "0px" : "80px"}>
        <Menu className="menu">{renderMenuItems(NavConfig)}</Menu>
        {!collapsed && (
          <div className="btnBackground">
            <button className="btn" onClick={useLogout}>
              Sair
            </button>
          </div>
        )}
      </Sidebar>
    </SidebarWrapper>
  );
}

export default SidebarComponent;
