const url = () => {
  return {
    baseURL: 'https://ms.frexco.com.br/api/deliveries',
    baseURLAbacateApi: 'https://abacate.frexco.com.br',
    baseURLFilesApi: 'https://ms.frexco.com.br/api/files',
    baseURLOrderApi: 'https://ms.frexco.com.br/api/orders/v1',
    baseRegionApi: 'https://ms.frexco.com.br/region',
    msUrl: 'https://ms.frexco.com.br',
    breakUrl: 'https://carambola.frexco.com.br/',
  }
}

const { baseURL, breakUrl, baseURLAbacateApi, baseURLFilesApi, baseURLOrderApi, baseRegionApi, msUrl } = url()
export { baseURL, breakUrl, baseURLAbacateApi, baseURLFilesApi, baseURLOrderApi, baseRegionApi, msUrl }
