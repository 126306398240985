import { api as apiService, ApiService } from "./api";
import { toast } from "react-toastify";
import { baseURL } from "settings";
import { ILineup } from "interfaces/ILineup";
import { dateToday } from "utils/dateToday";
import { IParams } from "interfaces/IWaybill";

interface IWaybillParams {
  deliveryDate?: string;
  pageLimit?: number;
}

export interface IWaybill {
  id: number;
  route: number;
  deliveryDate: string;
  canAboveLimitBoxes: boolean;
}

interface IWaybillAllResponse {
  totalCount: number;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  results: IWaybill[];
}

interface IWaybillUpdateData {
  can_above_limit_boxes: boolean;
}

interface IDriverChangeHistoryParams {
  route?: number;
  startDeliveryDate?: string;
  endDeliveryDate?: string;
}

class RouteService {
  constructor(
    private readonly api: ApiService,
    private readonly baseURL: string
  ) { }

  public updateLineup = async (data: ILineup[]): Promise<ILineup[]> => {
    const params = {
      lineUp: data,
    };

    const response = await this.api.post(
      `${this.baseURL}/v1/delivery/waybill/line-up`,
      params
    );
    return response;
  };

  public putLineup = async (data: ILineup): Promise<ILineup> => {
    const params = {
      waybillId: data.waybillId,
      vehicleId: data.vehicleId,
    };

    const response = await this.api.put(
      `${this.baseURL}/v1/delivery/waybill/line-up`,
      params
    );
    return response;
  };

  public getWaybill = async (route: number): Promise<any> => {
    const params = {
      route: route,
    };

    return await this.api.get(`${this.baseURL}/v1/delivery/waybill`, {
      params,
    });
  };

  public getAgileRoutes = async () => {
    return this.api.get(`${this.baseURL}/v1/delivery/waybill/export-routes`)
  }

  public exportRoutes = async (selectedRoutes: Array<number>): Promise<any> => {
    const params = {
      selectedRoutes: selectedRoutes,
      params: {
        deliveryDate: null,
      },
    };
    const response = await this.api.post(
      `${this.baseURL}/v1/delivery/waybill/export-routes`,
      params
    );
    return response;
  };

  public getVehicleRotation = (params: IParams) => {
    return this.api.get(`${this.baseURL}/v1/delivery/vehicle/rotation`, { params });
  };

  public getWaybillLineUp = (params: IParams) => {
    return this.api.get(`${this.baseURL}/v1/delivery/waybill/line-up`, { params });
  };

  public mirrorAgile = async (): Promise<any> => {
    const params = {
      action: "mirrorAgile",
      params: {
        deliveryDate: dateToday(1),
      },
    };

    const response = await this.api.patch(
      `${this.baseURL}/v1/delivery/waybill/line-up`,
      params
    );
    toast.success("Agile espelhado com sucesso!");
    return response;
  };

  public updateTransship = async (
    transshipPointId: number,
    routes: number[],
    deliveryDate: string
  ): Promise<any> => {
    const params = {
      action: "changeTransship",
      params: {
        deliveryDate: deliveryDate || dateToday(1),
        newTransshipCode: transshipPointId,
        routes: routes, //opcional
      },
    };
    const response = await this.api.patch(
      `${this.baseURL}/v1/delivery/waybill/line-up`,
      params
    );
    toast.success("Transbordo alterado com sucesso!");
    return response;
  };

  public getAllWaybill = async (
    params: IWaybillParams
  ): Promise<IWaybillAllResponse> => {
    return await this.api.get(`${this.baseURL}/v1/delivery/waybill`, {
      params,
    });
  };

  public updateWaybill = async (
    id: number,
    data: Partial<IWaybillUpdateData>
  ) => {
    return await this.api.patch(
      `${this.baseURL}/v1/delivery/waybill/${id}`,
      data
    );
  };

  public getDriverChangeHistory = async (params: IDriverChangeHistoryParams) => {
    return await this.api.get(`${this.baseURL}/v1/delivery/driver-change-history`, { params });
  }
}

export const routeService = new RouteService(apiService, baseURL);
