import { ILogin } from "interfaces/ILogin";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { api } from "services/api";
import { authenticationService } from "services/authenticationService";
import { baseURL } from "settings";

interface IAuthContext {
  username: string;
  setUsername: (name: string) => void;
  userGroups: string[];
  setUserGroups: (groups: string[]) => void;
  handleSignIn: (x: ILogin) => Promise<void>;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function AuthProvider({ children }: any) {
  const navigate = useNavigate();
  const [username, setUsername] = useState(() => localStorage.getItem("username") || "");
  const [userGroups, setUserGroups] = useState<string[]>(() => {
    const groups = localStorage.getItem("userGroups");
    return groups ? JSON.parse(groups) : [];
  });

  const getUserData = async (token: string) => {
    try {
      const response = await api.get(`${baseURL}/me/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUsername(response.userLogin);
      setUserGroups(response.userGroupsNames);

      localStorage.setItem("username", response.userLogin);
      localStorage.setItem("userGroups", JSON.stringify(response.userGroupsNames));
    } catch (e) {
      toast.error("Houve um erro ao buscar o usuário no sistema");
    }
  };

  const handleSignIn = async (data: ILogin) => {
    try {
      const { access } = await authenticationService.postLogin(data);

      localStorage.setItem("bearerToken", access);

      await getUserData(access);
      api.instance.defaults.headers["Authorization"] = `Bearer ${access}`;

      navigate("/home");
      toast.success("Login realizado com sucesso!");
    } catch (e: any) {
      toast.error(e?.message ?? "Este usuário não existe no sistema");
    }
  };

  return (
    <AuthContext.Provider value={{ username, setUsername, userGroups, setUserGroups, handleSignIn }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
